import React, { useState } from 'react';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import Avatar from 'components/Avatar';
import { Buddie } from '__generated__/graphql';
import { ImageSizeType } from 'types/types';

interface CreateConfirmationPracticesGroupProps {
  onSaveGroup: (isSolo: boolean, selectedBuddies: string[]) => void;
  buddies: Buddie[];
}

export default function CreateConfirmationPracticesGroup({ buddies, onSaveGroup }: CreateConfirmationPracticesGroupProps) {
  const [isSolo, setIsSolo] = useState<boolean>(true);
  const [selectedBuddies, setSelectedBuddies] = React.useState<string[]>([]);

  const handleSelectBuddy = (id: string) => {
    const currentIndex = selectedBuddies.indexOf(id);
    const newBuddieList = [...selectedBuddies];

    if (currentIndex === -1) {
      newBuddieList.push(id);
    } else {
      newBuddieList.splice(currentIndex, 1);
    }

    setSelectedBuddies(newBuddieList);
  };

  const handleIsSolo = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === 'solo') {
      setIsSolo(true);
      setSelectedBuddies([]);
    } else {
      setIsSolo(false);
    }
  };

  const onNewGroup = () => {
    onSaveGroup(isSolo, selectedBuddies);
  };

  return (
    <div className="flex flex-col justify-between">
      <div>
        <div className="text-black font-semibold text-md leading-md sm:text-display-sm  sm:leading-display-sm mb-1">
          Are you reflecting on the Confirmation Practices statements with buddies or by yourself?
        </div>
        <RadioGroup aria-labelledby="demo-radio-buttons-group-label" defaultValue="female" name="radio-buttons-group">
          <FormControlLabel value="solo" control={<Radio checked={isSolo} onChange={handleIsSolo} />} label="By myself" />
          <FormControlLabel value="withBuddy" control={<Radio checked={!isSolo} onChange={handleIsSolo} />} label="With my buddies" />
        </RadioGroup>
        {!isSolo && (
          <div className="">
            {buddies.map((buddy) => {
              return (
                <div key={buddy.id} className="flex flex-row p-2 gap-4 items-center">
                  <Avatar key={buddy.avatar} avatar={buddy.avatar} alt={`${buddy.fullName}`} size={ImageSizeType.MD} border />
                  <div>{`${buddy.fullName}`} </div>
                  <input
                    onChange={() => handleSelectBuddy(buddy.id || '')}
                    checked={selectedBuddies?.includes(buddy.id || '')}
                    type="checkbox"
                    // eslint-disable-next-line max-len
                    className="w-4 h-4 text-primary-300 bg-gray-100 border-gray-300 rounded "
                  />
                </div>
              );
            })}
          </div>
        )}
      </div>
      <button
        type="button"
        data-cy="create-confirmation-practice-group"
        onClick={onNewGroup}
        className="text-white bg-primary-700 rounded-lg px-5 py-2.5 font-semibold text-md leading-md w-full md:w-fit"
      >
        Start Confirmation Practices
      </button>
    </div>
  );
}
