import React, { useState, SetStateAction, Dispatch } from 'react';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import Avatar from '@mui/material/Avatar';
import AddIcon from '@mui/icons-material/Add';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import useMediaQuery from '@mui/material/useMediaQuery';
import { User } from 'types/types';
import { ShiftRunTypeEnum, ShiftType as ShiftTypeEnum, VisitStatus } from 'pages/ReportingHours/service';
import { Shift as ShiftType, SupportWorker as SupportWorkerType } from '../../../__generated__/graphql';
import { formatAmPm, shiftDuration, rotaPeriod, formatTime, getShiftLength } from '../../../services/helpers';
import ShiftVisit from './ShiftVisit';
import { getShiftStatus } from '../service';

interface ShiftProps {
  setSelectedShift: Dispatch<SetStateAction<ShiftType | null>>;
  toggleSupportWorkerModal: (toggle: boolean) => void;
  toggleEditModal: (toggle: boolean) => void;
  toggleRemoveModal: (toggle: boolean) => void;
  toggleCancelModal: (toggle: boolean) => void;
  toggleEnableModal: (toggle: boolean) => void;
  toggleAddVisitToShiftModal: (toggle: boolean) => void;
  toggleRemoveActivityModal: (toggle: boolean) => void;
  toggleEditActivityModal: (toggle: boolean) => void;
  toggleAddActivityToShiftModal: (toggle: boolean) => void;
  toggleChangeShiftTypeModal: (toggle: boolean) => void;
  toggleChangeShiftTimeModal: (toggle: boolean) => void;
  shifts: ShiftType[];
  user: User;
  supportWorkers: SupportWorkerType[];
  setCustomer: (customer: string) => void;
  open: boolean;
  onExpandShift: () => void;
  setToggledShiftId: Dispatch<SetStateAction<string | null>>;
  index: string;
  onRefreshVisitForBirdie: (id: string) => void;
  onRevertMoveVisit: (teamId: string, rotaId: string, visitId: string) => void;
}

export default function Shift({
  setSelectedShift,
  toggleSupportWorkerModal,
  toggleEditModal,
  toggleRemoveModal,
  toggleCancelModal,
  toggleEnableModal,
  toggleAddVisitToShiftModal,
  toggleRemoveActivityModal,
  toggleEditActivityModal,
  toggleAddActivityToShiftModal,
  toggleChangeShiftTypeModal,
  toggleChangeShiftTimeModal,
  shifts,
  user,
  supportWorkers,
  setCustomer,
  open,
  onExpandShift,
  setToggledShiftId,
  index,
  onRefreshVisitForBirdie,
  onRevertMoveVisit,
}: ShiftProps) {
  const [isOpen, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);
  const onMenuClick = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event?.currentTarget);
  const onClose = () => setAnchorEl(null);

  const filterByMoved = (sh: ShiftType) => {
    return sh.status !== VisitStatus.moved;
  };

  const filterByCancelledVisit = (sh: ShiftType) => {
    return sh.status !== VisitStatus.cancelled;
  };

  const filterByType = (sh: ShiftType) => {
    return sh.type === ShiftTypeEnum.visit || sh.type === ShiftTypeEnum.oneoff;
  };

  const filterByActivity = (sh: ShiftType) => {
    return sh.type === ShiftTypeEnum.activity;
  };

  const { shiftRun } = shifts[0];
  const ownerIdOfShift = shifts[0].shiftRun ? shifts[0].shiftRun?.ownerId : null;
  const shiftId = shifts[0].id ? shifts[0].shiftRun?.id : null;
  const ownerOfShift = supportWorkers.find((sw: SupportWorkerType) => sw.id === ownerIdOfShift);

  const activeShifts = shifts.filter((sh: ShiftType) => filterByMoved(sh) && filterByCancelledVisit(sh) && filterByType(sh));
  const shiftDurationMinutes = shiftDuration(activeShifts);
  const shiftLength = getShiftLength(shiftDurationMinutes);

  const activeActivity = shifts.filter((sh: ShiftType) => filterByActivity(sh));
  const activityDurationMinutes = shiftDuration(activeActivity);
  const activityLength = getShiftLength(activityDurationMinutes);

  const tbcs = shifts.filter((sh: ShiftType) => sh.secondarySupportWorker === null && sh.status === VisitStatus.active);
  const cancelled = shifts.filter((sh: ShiftType) => sh.status === VisitStatus.cancelled);
  const moved = shifts.filter((sh: ShiftType) => sh.status === VisitStatus.moved);
  const workers = shifts.filter((sh: ShiftType) => sh.secondarySupportWorker).map((s: ShiftType) => s.secondarySupportWorker?.id);

  const uniqueWorkers = [...new Set(workers)];

  const isTbcShift = shiftRun?.type === ShiftRunTypeEnum.agreed && !shiftRun?.ownerId;
  const isAgreedShift = shiftRun?.type === ShiftRunTypeEnum.agreed && shiftRun?.ownerId;
  const isFlexiShift = shiftRun?.type === ShiftRunTypeEnum.oneoff && shiftRun?.ownerId;
  // legacy, isOneOffShift wont happen in the future, kept for old rotas
  const isOneOffShift = shiftRun?.type === ShiftRunTypeEnum.oneoff && !shiftRun?.ownerId;

  const startOfShift = shiftRun?.startDateTime;
  const endOfShift = shiftRun?.endDateTime;
  const isDesktop = useMediaQuery('(min-width:600px)');

  const isCrossTeam = (shs: ShiftType[]) => {
    return shs.some((sh) => sh?.origin && sh?.origin?.teamId !== sh?.teamId);
  };

  const onAddVisitToShift = (shift: ShiftType) => {
    setSelectedShift(shift);
    toggleAddVisitToShiftModal(true);
    onClose();
  };

  const onChangeSupportWorker = (shift: ShiftType) => {
    setSelectedShift(shift);
    toggleSupportWorkerModal(true);
  };

  const onAddActivity = (shift: ShiftType) => {
    setSelectedShift(shift);
    toggleAddActivityToShiftModal(true);
    onClose();
  };

  const changeShiftType = (sh: ShiftType) => {
    setSelectedShift(sh);
    toggleChangeShiftTypeModal(true);
    onClose();
  };

  const changeShiftTime = (sh: ShiftType) => {
    setSelectedShift(sh);
    toggleChangeShiftTimeModal(true);
    onClose();
  };

  const elementId = shiftId || Date.now().toString();

  const onToggle = () => {
    setToggledShiftId(elementId);
    if (isDesktop) {
      onExpandShift();
    } else {
      setOpen((current) => !current);
    }
  };

  const disableMovedShift = shifts.length === moved.length;
  const shiftStatus = getShiftStatus(shifts, shiftRun);

  return (
    <div
      id={elementId}
      style={{ opacity: (endOfShift && endOfShift < Date.now()) || disableMovedShift ? '0.5' : '' }}
      className={`shift sm ${(isDesktop && open) || (!isDesktop && isOpen) ? 'open' : 'close'} ${ownerOfShift ? null : 'warning'}`}
    >
      <Grid container sx={{ paddingLeft: '1em', paddingRight: '0.8em', marginTop: '1em' }}>
        <Grid item xs={11}>
          <h4>
            <span id={`shift-date-${index}`} data-cy={`shift-date-${index}`}>
              {rotaPeriod(shifts)}
            </span>
            <br />
            <span data-cy={`shift-time-${index}`}>
              {formatTime(startOfShift || 0, 'HH:mm')} - {formatTime(endOfShift || 0, 'HH:mm')}
            </span>
            <br />
            <span data-cy={`shift-activities-${index}`}>
              <Typography>{activityLength || 0} in activities</Typography>
            </span>
          </h4>
        </Grid>
        <Grid item xs={1}>
          <Tooltip data-cy={`shift-${index}`} onClick={onToggle} title="View visits of this shift" sx={{ cursor: 'pointer' }}>
            {(isDesktop && open) || (!isDesktop && isOpen) ? (
              <KeyboardArrowUpIcon sx={{ cursor: 'pointer' }} />
            ) : (
              <KeyboardArrowDownIcon sx={{ cursor: 'pointer' }} />
            )}
          </Tooltip>
        </Grid>
      </Grid>
      <Grid container sx={{ paddingLeft: '1em', paddingRight: '0.8em' }}>
        <Grid item xs={11}>
          <h4>
            <Typography>{shiftLength || 0} in visits</Typography>
          </h4>
        </Grid>
        <Grid item xs={1}>
          {shifts?.length > 0 && (
            <>
              <Tooltip title="Add a new visit or activity to this shift" sx={{ cursor: 'pointer', marginTop: '0.4em', marginLeft: '-0.3em' }}>
                <IconButton
                  data-cy={`shift-${index}-add-menu`}
                  onClick={onMenuClick}
                  size="small"
                  aria-controls={open ? 'account-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                >
                  <AddIcon />
                </IconButton>
              </Tooltip>
              <Menu
                id="menu"
                anchorEl={anchorEl}
                open={openMenu}
                onClose={onClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                <MenuItem disabled={shiftStatus.shiftIsFull || false} data-cy={`shift-${index}-add-visit`} onClick={() => onAddVisitToShift(shifts[0])}>
                  Add Visit
                </MenuItem>
                {!isTbcShift && (ownerIdOfShift === user.profile || user.permissions?.admin) && (
                  <MenuItem disabled={shiftStatus.shiftIsFull || false} data-cy={`shift-${index}-add-activity`} onClick={() => onAddActivity(shifts[0])}>
                    Add Activity
                  </MenuItem>
                )}
                {!isTbcShift && (
                  <MenuItem data-cy={`shift-${index}-change-shift-type`} onClick={() => changeShiftType(shifts[0])}>
                    Change Shift Type
                  </MenuItem>
                )}
                {isAgreedShift && (
                  <MenuItem data-cy={`shift-${index}-change-shift-time`} onClick={() => changeShiftTime(shifts[0])}>
                    Change Shift Time
                  </MenuItem>
                )}
              </Menu>
            </>
          )}
          {/* {!user?.isGuest && shifts?.length > 0 && ownerIdOfShift !== user.profile && showActivityFeature && (
            <>
              <Tooltip
                data-cy={`add-visit-${index}`}
                onClick={() => onAddVisitToShift(shifts[0])}
                title="Add a new visit to this shift"
                sx={{ cursor: 'pointer', marginTop: '0.4em', paddingLeft: '-0.8em' }}
              >
                <AddIcon />
              </Tooltip>
              <Tooltip title="Change the shift type" sx={{ cursor: 'pointer', marginRight: '0.2em' }}>
                <SwitchAccessShortcutIcon color="primary" onClick={() => changeShiftType(shifts[0])} />
              </Tooltip>
            </>
          )} */}
        </Grid>
      </Grid>
      {shifts.map((shift: ShiftType) => {
        if (!shift) {
          return null;
        }
        return (
          <ShiftVisit
            index={index}
            shift={shift}
            setCustomer={setCustomer}
            user={user}
            toggleEditModal={toggleEditModal}
            toggleRemoveModal={toggleRemoveModal}
            toggleCancelModal={toggleCancelModal}
            toggleEnableModal={toggleEnableModal}
            toggleEditActivityModal={toggleEditActivityModal}
            onRefreshVisitForBirdie={onRefreshVisitForBirdie}
            onRevertMoveVisit={onRevertMoveVisit}
            setSelectedShift={setSelectedShift}
            toggleRemoveActivityModal={toggleRemoveActivityModal}
            onChangeSupportWorker={onChangeSupportWorker}
            shiftLength={shifts.length}
            // crossTeam={crossTeam}
          />
        );
      })}
      {isFlexiShift && (
        <div className="support warning">
          <Grid container>
            <Grid item xs={4} md={2}>
              <Tooltip title={`This banked hours shift is covered by ${ownerOfShift?.fullName}`} sx={{ mt: '-0.4em' }}>
                <Badge
                  color="secondary"
                  overlap="rectangular"
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                  badgeContent={`${ownerOfShift?.fullName?.replace('-', ' ').split(' ')[0]}${uniqueWorkers.length > 1 ? `+${uniqueWorkers.length - 1}` : ''}`}
                >
                  <Avatar data-cy={`shift-${index}-owner`} className="colleague" variant="circular" src={ownerOfShift?.avatar} />
                </Badge>
              </Tooltip>
            </Grid>
            <Grid item xs={8} md={10}>
              <Typography align="right">
                <Chip data-cy={`shift-${index}-is-flexi`} size="small" label="Banked" color="info" sx={{ mr: '0.2em' }} />
                {tbcs?.length > 0 && (
                  <Chip
                    data-cy={`shift-${index}-is-tbc`}
                    size="small"
                    label={`${tbcs.length > 1 ? `+${tbcs.length} TBCs` : 'TBC'}`}
                    color="error"
                    sx={{ mr: '0.2em' }}
                  />
                )}
                {cancelled?.length > 0 && <Chip data-cy={`shift-${index}-is-cancelled`} size="small" label="Cancelled" color="primary" sx={{ mr: '0.2em' }} />}
                {isCrossTeam(shifts) && <Chip data-cy={`shift-${index}-is-cross-team`} size="small" label="X-Team" color="primary" sx={{ mr: '0.2em' }} />}
                {startOfShift && formatAmPm(startOfShift) === 'am' ? (
                  <Chip data-cy={`shift-${index}-is-am`} size="small" label="AM" color="secondary" sx={{ mr: '0.2em' }} />
                ) : (
                  <Chip data-cy={`shift-${index}-is-pm`} size="small" label="PM" color="info" sx={{ mr: '0.2em' }} />
                )}
              </Typography>
            </Grid>
          </Grid>
        </div>
      )}
      {isAgreedShift && (
        <div className="support">
          <Grid container>
            <Grid item xs={4} md={2}>
              <Tooltip title={`This agreed shift is supported by ${ownerOfShift?.fullName}`} sx={{ mt: '-0.4em' }}>
                <Badge
                  color="secondary"
                  overlap="rectangular"
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                  badgeContent={`${ownerOfShift?.fullName?.replace('-', ' ').split(' ')[0]}${uniqueWorkers.length > 1 ? `+${uniqueWorkers.length - 1}` : ''}`}
                >
                  <Avatar
                    data-cy={`shift-${index}-owner`}
                    className="colleague"
                    variant="circular"
                    src={ownerOfShift?.avatar}
                    alt={`cover for this visit ${ownerOfShift?.fullName}`}
                  />
                </Badge>
              </Tooltip>
            </Grid>
            <Grid item xs={8} md={10}>
              <Typography align="right">
                <Chip data-cy={`shift-${index}-is-agreed`} size="small" label="Fixed" color="primary" sx={{ mr: '0.2em' }} />
                {tbcs.length > 0 && (
                  <Chip
                    data-cy={`shift-${index}-is-tbc`}
                    size="small"
                    label={`${tbcs.length > 1 ? `${tbcs.length} TBCs` : 'TBC'}`}
                    color="error"
                    sx={{ mr: '0.2em' }}
                  />
                )}
                {shiftStatus.shiftIsFull && <Chip data-cy={`shift-${index}-is-full`} size="small" label="Full" color="warning" sx={{ mr: '0.2em' }} />}
                {cancelled.length > 0 && <Chip data-cy={`shift-${index}-is-cancelled`} size="small" label="Cancelled" color="primary" sx={{ mr: '0.2em' }} />}
                {isCrossTeam(shifts) && <Chip data-cy={`shift-${index}-is-cross-team`} size="small" label="X-Team" color="primary" sx={{ mr: '0.2em' }} />}
                {startOfShift && formatAmPm(startOfShift) === 'am' ? (
                  <Chip data-cy={`shift-${index}-is-am`} size="small" label="AM" color="secondary" sx={{ mr: '0.2em' }} />
                ) : (
                  <Chip data-cy={`shift-${index}-is-pm`} size="small" label="PM" color="info" sx={{ mr: '0.2em' }} />
                )}
              </Typography>
            </Grid>
          </Grid>
        </div>
      )}
      {isOneOffShift && (
        <div className="support warning">
          <Grid container>
            <Grid item xs={4} md={2} />
            <Grid item xs={8} md={10}>
              <Typography align="right">
                <Chip data-cy={`shift-${index}-is-oneoff`} size="small" label="One-off" color="secondary" sx={{ mr: '0.2em' }} />
                {tbcs?.length > 0 && (
                  <Chip
                    data-cy={`shift-${index}-is-tbc`}
                    size="small"
                    label={`${tbcs.length > 1 ? `+${tbcs.length} TBCs` : 'TBC'}`}
                    color="error"
                    sx={{ mr: '0.2em' }}
                  />
                )}
                {cancelled?.length > 0 && <Chip data-cy={`shift-${index}-is-cancelled`} size="small" label="Cancelled" color="primary" sx={{ mr: '0.2em' }} />}
                {moved?.length > 0 && <Chip data-cy={`shift-${index}-is-moved`} size="small" label="Moved" color="error" sx={{ mr: '0.2em' }} />}
                {isCrossTeam(shifts) && <Chip data-cy={`shift-${index}-is-cross-team`} size="small" label="X-Team" color="primary" sx={{ mr: '0.2em' }} />}
                {startOfShift && formatAmPm(startOfShift) === 'am' ? (
                  <Chip data-cy={`shift-${index}-is-am`} size="small" label="AM" color="secondary" sx={{ mr: '0.2em' }} />
                ) : (
                  <Chip data-cy={`shift-${index}-is-pm`} size="small" label="PM" color="info" sx={{ mr: '0.2em' }} />
                )}
              </Typography>
            </Grid>
          </Grid>
        </div>
      )}
      {isTbcShift && (
        <div className="support">
          <Grid container>
            <Grid item xs={4} md={2} />
            <Grid item xs={8} md={10}>
              <Typography align="right">
                {tbcs.length > 0 && (
                  <Chip
                    data-cy={`shift-${index}-is-tbc`}
                    size="small"
                    label={`${tbcs.length > 1 ? `${tbcs.length} TBCs` : 'TBC'}`}
                    color="error"
                    sx={{ mr: '0.2em' }}
                  />
                )}
                {cancelled.length > 0 && <Chip data-cy={`shift-${index}-is-cancelled`} size="small" label="Cancelled" color="primary" sx={{ mr: '0.2em' }} />}
                {/* {moved.length > 0 && <Chip data-cy={`shift-${index}-is-moved`} size="small" label="Moved" color="error" sx={{ mr: '0.2em' }} />} */}
                {startOfShift && formatAmPm(startOfShift) === 'am' ? (
                  <Chip data-cy={`shift-${index}-is-am`} size="small" label="AM" color="secondary" sx={{ mr: '0.2em' }} />
                ) : (
                  <Chip data-cy={`shift-${index}-is-pm`} size="small" label="PM" color="info" sx={{ mr: '0.2em' }} />
                )}
              </Typography>
            </Grid>
          </Grid>
        </div>
      )}
    </div>
  );
}
