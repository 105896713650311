import { Input } from 'components';
import DatePicker from 'components/DatePicker';
import { Invoice } from '__generated__/graphql';
import { useEffect, useState } from 'react';
import { MapInvoice } from './Mapper';

interface InvoiceUpdateProps {
  invoice: Invoice | undefined;
  onSubmit: (invoice: Invoice) => void;
}

export default function InvoiceUpdate({ invoice, onSubmit }: InvoiceUpdateProps) {
  const [name, setName] = useState<string>('');
  const [invoiceDate, setInvoiceDate] = useState<Date | null>(null);
  const [dueDate, setDueDate] = useState<Date | null>(null);
  const [invoiceNumber, setInvoiceNumber] = useState<string>('');
  const [proRata, setProRata] = useState<string | null>('None');

  useEffect(() => {
    setName(invoice?.name || '');
    setInvoiceDate(new Date(invoice?.invoiceDate || new Date()));
    setDueDate(new Date(invoice?.dueDate || new Date()));
    setInvoiceNumber(invoice?.invoiceNumber || '');
    setProRata(invoice?.proRataType?.selectedOption || null);
  }, [invoice]);

  const onSaveInvoice = () => {
    onSubmit({
      ...MapInvoice(invoice),
      name,
      invoiceDate: invoiceDate?.getTime(),
      dueDate: dueDate?.getTime(),
      invoiceNumber,
      proRataType: {
        selectedOption: proRata !== 'None' ? proRata : null,
        past: invoice?.proRataType?.past,
        future: invoice?.proRataType?.future,
        startOfAdjustment: invoice?.proRataType?.startOfAdjustment,
        endOfAdjustment: invoice?.proRataType?.endOfAdjustment,
      },
    });
  };

  return (
    <div className="mt-8 sm:mt-12">
      <div className="mb-5">
        <div className="text-sm font-medium text-gray-700 capitalize w-auto">Name</div>
        <div className="mt-2">
          <Input value={name} onChange={setName} testId="invoice-name" />
        </div>
      </div>
      <div className="mb-5">
        <div className="text-sm font-medium text-gray-700 capitalize w-auto">Invoice Date</div>
        <div className="mt-2">
          <DatePicker testId="invoice-date" value={invoiceDate ? new Date(invoiceDate) : undefined} onChange={setInvoiceDate} />
        </div>
      </div>
      <div className="mb-5">
        <div className="text-sm font-medium text-gray-700 capitalize w-auto">Due Date</div>
        <div className="mt-2">
          <DatePicker testId="invoice-due-date" value={dueDate ? new Date(dueDate) : undefined} onChange={setDueDate} />
        </div>
      </div>
      <div className="mb-5">
        <div className="text-sm font-medium text-gray-700 capitalize w-auto">Invoice Number</div>
        <div className="mt-2">
          <Input value={invoiceNumber} onChange={setInvoiceNumber} testId="invoice-number" />
        </div>
      </div>

      {invoice?.customerPackage && (
        <div className="flex justify-end">
          <button type="button" onClick={onSaveInvoice} className="text-white bg-primary-700 rounded-lg px-5 py-2.5 font-semibold text-md leading-md">
            Save
          </button>
        </div>
      )}
    </div>
  );
}
