const Alert = ({ message, title }: { message: string; title?: string }) => {
  return (
    <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
      {title && <strong className="font-bold pr-3">{title}</strong>}
      <span className="block sm:inline">{message}</span>
    </div>
  );
};

export default Alert;
