import React, { useState, useEffect, useMemo } from 'react';
import { useUser, UserContextProps } from 'context/userContext';
import { useParams, useNavigate } from 'react-router-dom';
import Helmet from 'react-helmet';
import MobileStepper from '@mui/material/MobileStepper';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import { LocationBar } from 'components';
import { List } from '@mui/icons-material';
import { useGetAnswers, useGetConfirmationPracticeGroups, useGetConfirmationPracticeQuestions, useSaveAnswer } from 'api/hooks/useConfirmationPractices';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import { formatTime } from 'services/helpers';
import Loading from '../../components/Loading';
import Error from '../../components/Error';
import Form from './Form';
import questions from './questions.json';
import { Maybe } from '../../__generated__/graphql';

export default function ManageConfirmationPractices() {
  const { user, userLoading } = useUser() as UserContextProps;
  const { groupId } = useParams<{ groupId?: string }>();
  const { confirmationPracticeQuestions } = useGetConfirmationPracticeQuestions({ supportWorkerId: user?.profile });
  const activeQuestions = confirmationPracticeQuestions.filter((cpq) => cpq.isActive);
  const combinedQuestions = [...activeQuestions, ...questions];
  const { cpGroups } = useGetConfirmationPracticeGroups({ supportWorkerId: user?.profile });
  const cpGroup = cpGroups.find((cp) => cp.id === groupId);
  const questionIds = cpGroup?.questions;
  const mappedQuestions = questionIds?.length
    ? combinedQuestions.filter((q) => questionIds?.length && questionIds?.length > 0 && q.id && questionIds.includes(q.id))
    : combinedQuestions;
  const theme = useTheme();
  const navigate = useNavigate();
  const [id, setId] = useState<string>();
  const [questionId, setQuestionId] = useState<Maybe<string> | undefined>();
  const [activeStep, setActiveStep] = useState<number>(0);
  const [whyScore, setWhyScore] = useState<string>('');
  const [focus, setFocus] = useState<string>('');
  const [score, setScore] = useState<number>(3);
  const [createdDateTime, setDateTime] = useState<number>(Date.now());

  const maxSteps = mappedQuestions.length;

  useEffect(() => {
    if (activeStep !== maxSteps) {
      setQuestionId(mappedQuestions[activeStep].id);
    } else {
      setActiveStep(0);
    }
  }, [maxSteps, activeStep, questionId, mappedQuestions]);

  const { saveAnswer, mutationLoading, mutationError } = useSaveAnswer();
  const { answers, answerError, answerLoading } = useGetAnswers({
    supportWorkerId: user?.profile || '',
    groupId,
    questionId,
  });
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setQuestionId(mappedQuestions[activeStep]?.id);
  };

  const goBack = () => {
    navigate('/confirmation-practices');
  };

  useMemo(() => {
    if (answers) {
      const answer = answers[0];
      if (answer) {
        setId(answer.id);
        setWhyScore(answer.whyScore);
        setScore(answer.score);
        setFocus(answer.focus);
        setDateTime(answer.createdDateTime);
      } else {
        setId('');
        setWhyScore('');
        setWhyScore('');
        setScore(3);
        setFocus('');
      }
    }
  }, [answers]);

  if (answerLoading || userLoading || mutationLoading) return <Loading />;
  if (answerError || mutationError) return <Error />;

  const handleNext = async () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setQuestionId(mappedQuestions[activeStep].id || '');
    await saveAnswer({
      variables: {
        input: {
          id,
          groupId,
          questionId: mappedQuestions[activeStep].id,
          supportWorkerId: user?.profile,
          whyScore,
          score,
          focus,
          createdDateTime,
        },
      },
    });

    if (activeStep + 1 === maxSteps) {
      navigate('/confirmation-practices');
    }
  };

  return (
    <>
      <Helmet>
        <title>Confirmation Practices</title>
      </Helmet>
      <LocationBar section="Policies & Practices" page="Confirmation Practices" Icon={List} />
      <div className="my-10 px-4 md:px-[5%]">
        <button type="button" className="text-gray-500 font-semibold text-md leading-md flex items-center" onClick={goBack}>
          <ArrowLeftIcon className="mr-2 w-5 h-5" /> Back
        </button>
        <div className="flex items-start justify-between mt-16">
          <div className="text-black text-xl sm:text-display-sm font-semibold leading-xl sm:leading-display-sm mb-1">
            Confirmation Practices - {formatTime(createdDateTime, 'do MMMM')}
          </div>
        </div>
        <div className=" mt-16">
          {answers && (
            <>
              <Form
                whyScore={whyScore}
                setWhyScore={setWhyScore}
                focus={focus}
                setFocus={setFocus}
                score={score}
                setScore={setScore}
                question={mappedQuestions[activeStep].question || ''}
                title={mappedQuestions[activeStep].title || ''}
                checkList={mappedQuestions[activeStep].checkList || []}
              />
              <MobileStepper
                sx={{ mt: '1em', border: '1px solid #eee' }}
                variant="dots"
                steps={maxSteps}
                position="static"
                activeStep={activeStep}
                nextButton={
                  <Button sx={{ fontSize: '0.7em' }} size="small" onClick={handleNext} disabled={activeStep === maxSteps}>
                    {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                    {activeStep !== maxSteps - 1 ? 'Save & Next' : 'Save & Complete'}
                  </Button>
                }
                backButton={
                  <Button sx={{ fontSize: '0.7em' }} size="small" onClick={handleBack} disabled={activeStep === 0}>
                    {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                    Previous
                  </Button>
                }
              />
            </>
          )}
        </div>
      </div>
    </>
  );
}
