import { Diversity2Outlined, Edit } from '@mui/icons-material';
import { Customer } from '__generated__/graphql';
import { FormLabel, Input } from 'components';
import AvatarGroup from 'components/AvatarGroup';
import { PatternFormat } from 'react-number-format';
import { ImageSizeType } from 'types/types';

const WellbeingBoardPersonal = ({ customer, toggleLinkWorkerModal }: { customer: Customer | undefined; toggleLinkWorkerModal: (value: boolean) => void }) => {
  return (
    <div className="flex flex-col  mt-5 mb-5">
      <FormLabel label="NHS number">
        <div className="w-full lg:w-1/2">
          <PatternFormat
            disabled={true}
            placeholder="NHS number"
            // eslint-disable-next-line max-len
            className="bg-white px-5 py-2  disabled:bg-slate-50 disabled:text-slate-500 rounded-lg text-md leading-md text-gray-700 placeholder:text-gray-500 border border-gray-300 shadow-xs w-full"
            format="#### #### #### ####"
            value={customer?.nhsNumber || ''}
          />
        </div>
      </FormLabel>
      <FormLabel label="Phone">
        <div className="w-full lg:w-1/2">
          <Input
            disabled={true}
            value={customer?.phone || ''}
            placeholder="Phone"
            error={
              !customer?.phone ? (
                <p>
                  Supported person has no phone number &ensp;
                  <a className="underline" target="_blank" rel="noreferrer" href="https://www.hubspot.com/">
                    please check the phone number in hubspot
                  </a>
                </p>
              ) : undefined
            }
          />
        </div>
      </FormLabel>
      <FormLabel label="Address">
        <div className="w-full lg:w-1/2 flex flex-col gap-2">
          <Input
            disabled={true}
            value={customer?.address || ''}
            placeholder="Address"
            error={
              !customer?.address ? (
                <p>
                  Supported person has no address &ensp;
                  <a className="underline" target="_blank" rel="noreferrer" href="https://www.hubspot.com/">
                    please check the address in hubspot
                  </a>
                </p>
              ) : undefined
            }
          />
          <Input
            disabled={true}
            value={customer?.city || ''}
            placeholder="City"
            error={
              !customer?.city ? (
                <p>
                  Supported person has no city &ensp;
                  <a className="underline" target="_blank" rel="noreferrer" href="https://www.hubspot.com/">
                    please check the address in hubspot
                  </a>
                </p>
              ) : undefined
            }
          />
          <Input
            disabled={true}
            value={customer?.postcode || ''}
            placeholder="Postcode"
            error={
              !customer?.postcode ? (
                <p>
                  Supported person has no postcode &ensp;
                  <a className="underline" target="_blank" rel="noreferrer" href="https://www.hubspot.com/">
                    please check the address in hubspot
                  </a>
                </p>
              ) : undefined
            }
          />
        </div>
      </FormLabel>
      <FormLabel label="Link Workers">
        <div className="w-full lg:w-1/2 flex flex-col gap-2">
          <div className="flex flex-row items-center gap-2 text-gray-800 text-xl leading-xl mt-2" data-cy="wellbeing-board-link-workers">
            <Diversity2Outlined className="w-6 h-6 shrink-0" />
            {customer?.linkWorkers?.map((lw) => lw?.fullName).join(', ')}
          </div>
          <div className="flex flex-col sm:flex-row sm:items-center gap-3 mt-4">
            <AvatarGroup
              avatars={customer?.linkWorkers?.map((lw) => ({ avatar: lw?.avatar ?? '', alt: lw?.fullName ?? '', tooltip: lw?.fullName ?? '' })) ?? []}
              size={ImageSizeType.LG}
              max={3}
              border
            />
            <button
              type="button"
              onClick={() => toggleLinkWorkerModal(true)}
              className="text-white bg-primary-700 rounded-lg px-5 py-2.5 font-semibold text-md leading-md flex justify-center items-center gap-2"
              data-cy="wellbeing-board-update-link-workers-button"
            >
              <Edit className="!w-5 !h-5" />
              Change Link Workers
            </button>
          </div>
        </div>
      </FormLabel>
    </div>
  );
};

export default WellbeingBoardPersonal;
