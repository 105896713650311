import { format } from 'date-fns';
import { Customer } from '__generated__/graphql';
import { ArrowUpTrayIcon } from '@heroicons/react/24/outline';
import { CSVLink } from 'react-csv';

interface WellbeingBoardStatusesProps {
  customer: Customer | null | undefined;
}

const WellbeingBoardStatuses = ({ customer }: WellbeingBoardStatusesProps) => {
  const rows =
    customer?.wellbeingStatuses?.map((s) => {
      return { ...s, customerId: customer.id, customerName: `${customer.firstName} ${customer.lastName}`, date: format(s?.date ?? 0, 'dd/MM/yy') };
    }) || [];
  return (
    <div className="mt-3 md:mt-6 mb-6 ">
      <div className="flex justify-end place-items-center mb-8">
        <div className="flex items-center justify-between lg:justify-normal gap-3 md:gap-9 w-full lg:w-fit mt-6 lg:mt-0">
          <CSVLink filename="wellbeingStatus.csv" data={rows} className="text-gray-500 text-sm leading-sm font-semibold flex items-center hover:no-underline">
            <ArrowUpTrayIcon className="w-5 h-5 mr-2" />
            Export CSV
          </CSVLink>
        </div>
      </div>
      <div className="mt-3 md:mt-12 mb-10 scroll-m-3 overflow-x-auto overflow-y-hidden">
        <table className="table-auto min-w-full divide-y divide-gray-200">
          <thead>
            <tr>
              <td className="px-2 sm:px-4  md:px-6 py-3 text-left text-sm font-semibold text-gray-800"> Number</td>
              <td className="px-2 sm:px-4  md:px-6 py-3 text-left text-sm font-semibold text-gray-800"> Status</td>
              <td className="px-2 sm:px-4  md:px-6 py-3 text-left text-sm font-semibold text-gray-800">Date</td>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {customer?.wellbeingStatuses &&
              customer?.wellbeingStatuses?.length > 0 &&
              customer?.wellbeingStatuses?.map((wellbeingStatus, index) => {
                return (
                  <tr>
                    <td className="px-2 sm:px-4 md:px-6 py-4 sm:whitespace-nowrap text-gray-700 text-md leading-md">{index + 1}</td>

                    <td className="px-2 sm:px-4 md:px-6 py-4 sm:whitespace-nowrap text-gray-700 text-md leading-md">{wellbeingStatus?.status}</td>
                    <td className="px-2 sm:px-4 md:px-6 py-4 sm:whitespace-nowrap text-gray-700 text-md leading-md">
                      {format(wellbeingStatus?.date ?? 0, 'dd/MM/yy')}
                    </td>
                  </tr>
                );
              })}
            {customer?.wellbeingStatuses?.length === 0 && <div> No Statuses </div>}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export { WellbeingBoardStatuses };
