import { ConfirmationPracticeGroup } from '__generated__/graphql';
import { ImageSizeType } from 'types/types';
import AvatarGroup from 'components/AvatarGroup';
import { PencilIcon } from '@heroicons/react/24/outline';
import questions from 'pages/ConfirmationPractices/questions.json';
import { formatTime } from '../../services/helpers';

interface HistorysProps {
  groups: ConfirmationPracticeGroup[];
  onEditVisit: (data: ConfirmationPracticeGroup) => void;
}

export default function History({ groups, onEditVisit }: HistorysProps) {
  return (
    <div className="mt-3 md:mt-12 mb-10 scroll-m-3 overflow-x-auto overflow-y-hidden">
      <table className="table-auto min-w-full divide-y divide-gray-200">
        <thead>
          <tr>
            <td className="px-2 sm:px-4  md:px-6 py-3 text-left text-sm font-semibold text-gray-800"> Date</td>
            <td className="px-2 sm:px-4  md:px-6 py-3 text-left text-sm font-semibold text-gray-800">Progress</td>
            <td className="px-2 sm:px-4  md:px-6 py-3 text-left text-sm font-semibold text-gray-800"> Buddies</td>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          {groups.length > 0 &&
            groups.map((group, index) => {
              const groupQuestion = group?.questions || questions;
              return (
                <tr key={index}>
                  <td className="px-2 sm:px-4 md:px-6 py-4 sm:whitespace-nowrap text-gray-700 text-md leading-md">
                    {`${formatTime(group.createdDateTime || 0, 'do MMMM yyyy')}`}
                  </td>
                  <td className="px-2 sm:px-4 md:px-6 py-4 sm:whitespace-nowrap text-gray-700 text-md leading-md">{`${group?.answers?.length} of ${groupQuestion?.length}`}</td>
                  <td aria-label="buddies" className="px-2 sm:px-4 md:px-6 py-4 sm:whitespace-nowrap text-gray-700 text-md leading-md">
                    <AvatarGroup
                      avatars={group?.buddies?.map((lw) => ({ avatar: lw?.avatar ?? '', alt: lw?.fullName ?? '', tooltip: lw?.fullName ?? '' })) ?? []}
                      size={ImageSizeType.LG}
                      max={5}
                      border
                    />
                  </td>

                  <td className="px-2 sm:px-4 md:px-6 py-4 sm:whitespace-nowrap flex flex-row items-center gap-4">
                    <button type="button" aria-label="Edit button" onClick={() => onEditVisit(group)}>
                      <PencilIcon className="w-6 h-6 text-gray-500 hover:text-primary-600" />
                    </button>
                  </td>
                </tr>
              );
            })}
          {groups.length === 0 && <div> No Previous Confirmation Practices </div>}
        </tbody>
      </table>
    </div>
  );
}
