import { ConfirmationPracticeQuestion, ConfirmationPracticeQuestionInput } from '__generated__/graphql';
import { Input, ToggleButtons } from 'components';
import { SizeType } from 'types/types';
import { useForm, FormProvider, Controller } from 'react-hook-form';

interface ConfirmationPracticeQuestionFormProps {
  question: ConfirmationPracticeQuestion | null | undefined;
  onClose: () => void;
  onSaveConfirmationPracticeQuestion: (data: ConfirmationPracticeQuestionInput) => void;
  activeConfirmationQuestions: ConfirmationPracticeQuestion[];
}

export default function ConfirmationPracticeQuestionForm({
  question,
  onClose,
  onSaveConfirmationPracticeQuestion,
  activeConfirmationQuestions,
}: ConfirmationPracticeQuestionFormProps) {
  const methods = useForm<ConfirmationPracticeQuestionInput>({
    mode: 'onSubmit',
    defaultValues: {
      id: question?.id,
      isActive: question?.isActive,
      question: question?.question,
      supportWorkerId: question?.supportWorkerId,
      title: question?.title,
    },
  });
  const { handleSubmit, control, watch } = methods;
  const isActive = watch('isActive');

  const onSubmit = async (data: ConfirmationPracticeQuestionInput) => {
    await onSaveConfirmationPracticeQuestion(data);
    onClose();
  };

  const filteredQuestions = activeConfirmationQuestions.filter((q) => q.id !== question?.id);
  const statusDisabled = ((filteredQuestions.length || 0) === 5 && !isActive) || false;
  return (
    <FormProvider {...methods}>
      <form>
        <div className="justify-center items-center flex overflow-x-hidden fixed inset-0 z-[1200] outline-none focus:outline-none">
          <div className="relative w-auto mx-auto max-w-5xl">
            <div className="border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none p-6 max-h-screen md:max-h-[90vh] overflow-y-auto md:w-[480px] lg:w-[680px]">
              <div className="text-gray-900 text-lg leading-lg font-semibold mb-5">
                {question ? 'Edit Confirmation Practice Question' : 'Add Confirmation Practice Question'}
              </div>
              <div className="mb-5">
                <div className="text-sm font-medium text-gray-700 capitalize w-auto">What is this question about?</div>
                <div className="mt-2">
                  <Controller
                    name="title"
                    control={control}
                    rules={{ required: 'This is required.' }}
                    render={({ field: { onChange, value } }) => {
                      return <Input value={value || ''} onChange={onChange} testId="confirmation-practice-question-title" />;
                    }}
                  />
                </div>
              </div>

              <div className="mb-5">
                <div className="text-sm font-medium text-gray-700 capitalize w-auto">Question to be asked</div>
                <div className="mt-2">
                  <Controller
                    name="question"
                    control={control}
                    rules={{ required: 'This is required.' }}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <Input
                          value={value || ''}
                          placeholder="eg. I am confident giving feedback to my colleagues"
                          onChange={onChange}
                          testId="confirmation-practice-question"
                        />
                      );
                    }}
                  />
                </div>
              </div>
              <div className="mb-5">
                <div className="text-sm font-medium text-gray-700 capitalize w-auto">Is this question active?</div>
                <div className="text-sm font-normal text-gray-700 capitalize w-auto">
                  <span className="font-bold"> Yes </span>will show this question in your next confirmation practice.
                </div>
                <div className="text-sm font-normal text-gray-700 capitalize w-auto">
                  <span className="font-bold"> No </span> will stop showing it if you no longer want to see it
                </div>
                <div className="w-60 sm:w-96 mt-2">
                  <Controller
                    name="isActive"
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }) => {
                      return (
                        <>
                          <ToggleButtons
                            buttons={['Yes', 'No']}
                            size={SizeType.Full}
                            selectedButton={value ? 'Yes' : 'No'}
                            onSelectButton={() => {
                              onChange(!value);
                            }}
                            disabled={statusDisabled}
                          />
                          <p className="mt-2 text-sm text-error-600 dark:text-green-500">
                            <span className="font-medium">{error?.message}</span>
                          </p>
                          {statusDisabled && (
                            <p className="mt-2 text-sm text-error-600 dark:text-green-500">
                              <span className="font-medium">Please disable some to activate question</span>
                            </p>
                          )}
                        </>
                      );
                    }}
                  />
                </div>
              </div>
              <div className="flex justify-between items-center mt-16 md:mt-12">
                <button type="button" className="text-gray-500 font-semibold text-md leading-md" onClick={onClose}>
                  Cancel
                </button>
                <button
                  data-cy="save-template-button"
                  type="button"
                  onClick={handleSubmit(onSubmit)}
                  className="text-white disabled:opacity-50 bg-primary-700 rounded-lg px-5 py-2.5 font-semibold text-md leading-md"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="opacity-80 fixed inset-0 z-40 bg-gray-900" />
      </form>
    </FormProvider>
  );
}
