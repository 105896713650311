import { useEffect, useState, useMemo, useCallback } from 'react';
import Helmet from 'react-helmet';
import { SelectOption } from 'types/types';
import { Customer, Maybe, Shift, SupportWorker } from '__generated__/graphql';
import { formatTime, getPeriodsForYear, formatAmPm, getDurationInMinutes } from 'services/helpers';
import { getTime } from 'date-fns';
import intervalToDuration from 'date-fns/intervalToDuration';
import { AdjustmentsHorizontalIcon, XMarkIcon, ArrowUpTrayIcon } from '@heroicons/react/24/outline';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { ShiftType, VisitStatus } from 'pages/ReportingHours/service';
import { Loading, Filters, Table } from 'components';
import { useGeneric, GenericContextProps } from 'context/genericContext';
import { CSVLink } from 'react-csv';
import { UserContextProps, useUser } from 'context/userContext';
import { useGetSupportWorkers } from 'api/hooks/useSupportWorkers';
import { useGetCustomers } from 'api/hooks/useCustomers';
import { useGetPayrollReport } from 'api/hooks/usePayrollReport';
import { useSearchParams } from 'react-router-dom';
import Navigation from './Navigation';

type RotaReportType = {
  visitId: string;
  shiftId: string;
  teamId: string;
  teamName: Maybe<string> | undefined;
  customer: string;
  customerId: Maybe<string> | undefined;
  owner: string;
  period: string;
  supportWorker: string;
  supportWorkerId: Maybe<string> | undefined;
  type: Maybe<string> | undefined;
  employmentStatus: Maybe<string> | undefined;
  contractedHours: string | number;
  visitDate: string;
  visitStartTime: string;
  visitEndTime: string;
  visitLength: string;
  ampm: string;
  status: string;
  mileage: string;
  travelWithCustomerMileage: string;
  week: string | number;
  notes: Maybe<string> | undefined;
  whoCancelled: Maybe<string> | undefined;
  cancelledDateTime: string | null;
  daysBeforeVisitCancelled: string | null;
  activityType: Maybe<string> | undefined;
  oneOffVisitReason: Maybe<string> | undefined;
  oneOffVisitNote: Maybe<string> | undefined;
  isToBeRescheduled: string;
  origin: Maybe<string> | undefined;
  destination: Maybe<string> | undefined;
};

export default function MagicNumberReport() {
  const { user, userLoading } = useUser() as UserContextProps;
  const { teams: genericTeams } = useGeneric() as GenericContextProps;
  const [searchParams, setSearchParams] = useSearchParams();

  const periods = useMemo(() => {
    return getPeriodsForYear().map((period) => {
      return { label: formatTime(period, 'MMMM, yyyy'), value: period.toString() };
    });
  }, []);

  const oneOffVisitReasons = [
    { label: 'Is a reschedule of a cancelled job (covered by package)' },
    { label: 'Is an emergency visit (not chargeable)' },
    { label: 'Is an pre-agreed extra visit (chargeable)' },
    { label: 'Is an additional complementary visit using banked hours' },
    { label: 'New package starting at short notice (chargeable)' },
    { label: 'Package increase (chargeable)' },
  ];

  const oneOffVisitReasonOptions = oneOffVisitReasons.map((c) => ({ label: c.label, value: c.label }));

  const statusOptions = [
    { label: 'Active', value: VisitStatus.active },
    { label: 'Cancelled', value: VisitStatus.cancelled },
    { label: 'Moved', value: VisitStatus.moved },
  ];

  const visitTypeOptions = [
    { label: 'Visit', value: ShiftType.visit },
    { label: 'Activity', value: ShiftType.activity },
    { label: 'One Off', value: ShiftType.oneoff },
  ];

  const teams = useMemo(() => {
    return genericTeams?.map((t) => ({ value: t.teamId, label: t.teamName })) || [];
  }, [genericTeams]);

  const teamId = user?.teamId || '';
  const teamName = user?.teamName || '';
  const [period, setPeriod] = useState<string>(periods[0].value);
  const [team, setTeam] = useState<string>(teamId);
  const [showFiltersModal, setShowFiltersModal] = useState<boolean>(false);
  const [filters, setFilters] = useState([
    { name: 'period', selectedValue: periods[0], values: periods },
    { name: 'team', selectedValue: { value: teamId, label: teamName }, values: teams },
    { name: 'status', selectedValue: { value: 'all', label: 'All' }, values: [{ value: 'all', label: 'All' }, ...statusOptions] },
    { name: 'type', selectedValue: { value: 'all', label: 'All' }, values: [{ value: 'all', label: 'All' }, ...visitTypeOptions] },
    { name: 'one off reasons', selectedValue: { value: 'all', label: 'All' }, values: [{ value: 'all', label: 'All' }, ...oneOffVisitReasonOptions] },
  ]);

  useEffect(() => {
    const teamSearch = searchParams.get('team');
    const periodSearch = searchParams.get('period');
    const statusSearch = searchParams.get('status');

    const typeSearch = searchParams.get('type');
    const onOffSearch = searchParams.get('one off reasons');

    const newFilters = filters;
    if (periodSearch) {
      setPeriod(periodSearch);
      const periodOption = periods.find((t) => t.value === periodSearch);
      if (periodOption) {
        newFilters[0].selectedValue = periodOption;
      }
    }
    if (teamSearch) {
      setTeam(teamSearch);
      const teamOption = genericTeams?.map((t) => ({ value: t.teamId, label: t.teamName })).find((t) => t.value === teamSearch);
      if (teamOption) {
        newFilters[1].selectedValue = teamOption;
      }
    }
    if (statusSearch) {
      const statusOption = statusOptions.find((t) => t.value === statusSearch);
      if (statusOption) {
        newFilters[2].selectedValue = statusOption;
      }
    }
    if (typeSearch) {
      const typeOption = visitTypeOptions.find((t) => t.value === typeSearch);
      if (typeOption) {
        newFilters[3].selectedValue = typeOption;
      }
    }
    if (onOffSearch) {
      const oneOffOption = oneOffVisitReasonOptions.find((t) => t.value === onOffSearch);
      if (oneOffOption) {
        newFilters[4].selectedValue = oneOffOption;
      }
    }
    setFilters(newFilters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [genericTeams]);

  useEffect(() => {
    filters[1].values = teams;
    setFilters(filters);
  }, [filters, teams]);

  const onFilterChange = (name: string, value: SelectOption) => {
    const newFilters = [...filters];
    const filterIndex = newFilters.findIndex((f) => f.name === name);
    newFilters[filterIndex].selectedValue = value;
    setFilters(newFilters);
  };

  const { supportWorkers, loading: colleaguesLoading, error: colleaguesError } = useGetSupportWorkers({ showAll: true });
  const { customers, loading: customersLoading, error: customersError } = useGetCustomers({ showAll: true });
  const { getShifts, visitsLoading, visitsError, shifts } = useGetPayrollReport(period);

  useEffect(() => {
    getShifts({
      variables: {
        skip: !period,
        query: {
          teamId: team,
          startDateTime: getTime(new Date(new Date(parseInt(period, 10)).setMonth(new Date(parseInt(period, 10)).getMonth() - 1))),
          endDateTime: parseInt(period, 10),
        },
      },
    });
  }, [period, getShifts, team]);

  const filterByStatus = useCallback(
    (sw: Shift) => {
      if (filters[2].selectedValue.value === 'all') return true;
      if (sw.status === filters[2].selectedValue.value) return true;
      return false;
    },
    [filters],
  );

  const filterByType = useCallback(
    (sw: Shift) => {
      if (filters[3].selectedValue.value === 'all') return true;
      if (sw.type === filters[3].selectedValue.value) return true;
      return false;
    },
    [filters],
  );

  const filterByOneOffReason = useCallback(
    (sw: Shift) => {
      if (filters[4].selectedValue.value === 'all') return true;
      if (sw.oneOffVisitReason === filters[4].selectedValue.value) return true;
      return false;
    },
    [filters],
  );

  const filterReport = useCallback(() => {
    return shifts
      ?.filter((s: Shift) => filterByStatus(s))
      .filter((s: Shift) => filterByType(s))
      .filter((s: Shift) => filterByOneOffReason(s));
  }, [filterByOneOffReason, filterByStatus, filterByType, shifts]);

  const mappedShifts = useMemo(() => {
    return filterReport()?.map((sh: Shift) => {
      const cancelledDays = intervalToDuration({ start: new Date(sh.cancelledDateTime || 0), end: new Date(sh.startDateTime) });
      const sw = supportWorkers.find((s: SupportWorker) => s.id === sh.supportWorkerId);
      const secondarySupportWorker = supportWorkers.find((s: SupportWorker) => s.id === sh.secondarySupportWorkerId);
      const customer = customers.find((c: Customer) => c.id === sh.customerId);
      return {
        // id,
        visitId: sh.id,
        shiftId: sh.shiftId,
        teamId: sh.teamId,
        teamName: sh.teamName,
        customer: customer ? `${customer?.firstName} ${customer?.lastName}` : '',
        customerId: customer?.birdieId,
        owner: sw?.fullName || 'Not agreed',
        supportWorker: secondarySupportWorker?.fullName || 'tbc',
        supportWorkerId: secondarySupportWorker?.birdieId,
        type: sh.type,
        period: formatAmPm(sh.startDateTime),
        employmentStatus: secondarySupportWorker?.employmentStatus,
        contractedHours: secondarySupportWorker?.contractedHours ? secondarySupportWorker?.contractedHours : '',
        visitDate: formatTime(sh.startDateTime, 'dd/MM/yyyy'),
        visitStartTime: formatTime(sh.startDateTime, 'k:mm'),
        visitEndTime: formatTime(sh.endDateTime, 'k:mm'),
        visitLength: `${getDurationInMinutes(sh.startDateTime, sh.endDateTime)}mins`,
        ampm: formatAmPm(sh.startDateTime),
        status: sh.status,
        mileage: `${(sh.mileage || 0).toFixed(1)} m`,
        travelWithCustomerMileage: `${(sh.travelWithCustomerMileage || 0).toFixed(1)} m`,
        travelWithCustomerDestination: sh.travelWithCustomerDestination,
        week: sh?.week ? parseInt(sh?.week, 10) : '',
        notes: sh.notes,
        whoCancelled: sh.whoCancelled,
        cancelledDateTime: sh.cancelledDateTime ? formatTime(sh.cancelledDateTime, 'PPPP: HH:mm') : null,
        daysBeforeVisitCancelled: sh.cancelledDateTime ? `${cancelledDays.days} days, ${cancelledDays.hours} hours` : null,
        activityType: sh.activityType,
        oneOffVisitReason: sh.oneOffVisitReason,
        oneOffVisitNote: sh.oneOffVisitNote,
        isToBeRescheduled: sh.isToBeRescheduled ? 'yes' : 'no',
        origin: sh.origin?.fullName,
        destination: sh.destination?.fullName,
      };
    });
  }, [customers, filterReport, supportWorkers]);

  const columnHelper = createColumnHelper<RotaReportType>();

  const columns1 = useMemo(() => {
    return [
      columnHelper.accessor('teamName', {
        header: () => 'Team',
        cell: (info) => info.renderValue(),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('customer', {
        header: () => 'Customer',
        cell: (info) => info.renderValue(),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('owner', {
        header: () => 'Owner',
        cell: (info) => info.renderValue(),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('type', {
        header: () => 'Type',
        cell: (info) => info.renderValue(),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('visitDate', {
        header: () => 'Visit Date',
        cell: (info) => info.renderValue(),
        footer: (info) => info.column.id,
      }),

      columnHelper.accessor('visitStartTime', {
        header: () => 'Visit Start Time',
        cell: (info) => info.renderValue(),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('visitEndTime', {
        header: () => 'Visit End Time',
        cell: (info) => info.renderValue(),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('period', {
        header: () => 'Period',
        cell: (info) => info.renderValue(),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('visitLength', {
        header: () => 'Visit Length',
        cell: (info) => info.renderValue(),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('status', {
        header: () => 'Status',
        cell: (info) => info.renderValue(),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('mileage', {
        header: () => 'Mileage',
        cell: (info) => info.renderValue(),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('travelWithCustomerMileage', {
        header: () => 'Mileage with Customer',
        cell: (info) => info.renderValue(),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('week', {
        header: () => 'Week',
        cell: (info) => info.renderValue(),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('isToBeRescheduled', {
        header: () => 'To reschedule',
        cell: (info) => info.renderValue(),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('oneOffVisitReason', {
        header: () => 'One Off Reason',
        cell: (info) => info.renderValue(),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('oneOffVisitNote', {
        header: () => 'One Off Visit Note',
        cell: (info) => info.renderValue(),
        footer: (info) => info.column.id,
      }),
    ] as Array<ColumnDef<RotaReportType, unknown>>;
  }, [columnHelper]);

  const onSaveFilters = () => {
    const newSearchParamsArray = filters.map((f) => ({ [f.name]: f.selectedValue.value }));
    setSearchParams(Object.assign({}, ...newSearchParamsArray));
    setTeam(filters[1].selectedValue.value);
    setPeriod(filters[0].selectedValue.value);
    setShowFiltersModal(false);
  };

  if (visitsLoading || visitsError || colleaguesLoading || colleaguesError || customersLoading || customersError || userLoading) {
    return <Loading />;
  }

  return (
    <>
      <Helmet>
        <title>Rota Report</title>
      </Helmet>
      <Navigation report="Rota Report" />
      <div className="my-10 px-4 md:px-[5%]">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <div className="text-display-sm sm:text-display-lg leading-display-sm sm:leading-display-lg sm:tracking-display-lg text-black font-medium">
            Rota Report
          </div>
        </div>
        <div className="flex flex-col lg:flex-row items-center justify-between mt-8">
          <div className="flex flex-col md:flex-row items-center gap-4 md:gap-9 w-full md:w-fit">
            <div className="flex flex-col md:flex-row items-center gap-3 w-full md:w-fit">
              <button
                data-cy="show-filters-button"
                type="button"
                className={`relative border ${
                  team === 'all' && period === '0' ? 'border-gray-300' : 'border-gray-900'
                }  text-gray-700 text-md leading-md flex items-center px-4 py-2.5 rounded-lg shadow-xs justify-center w-full md:w-fit`}
                onClick={() => setShowFiltersModal(true)}
              >
                <AdjustmentsHorizontalIcon className="w-5 h-5 mr-2" />
                Filters
                {team !== 'all' && period !== '0' && (
                  <span className="absolute bg-gray-900 h-5 w-5 text-white rounded-full -top-2 -right-2 flex items-center justify-center text-xs font-semibold">
                    {team !== 'all' && period !== '0' ? '2' : '1'}
                  </span>
                )}
              </button>

              {team !== 'all' && (
                <div
                  data-cy="tag-2"
                  className="bg-gray-100 rounded-2xl border border-gray-300 px-2.5 py-1 text-gray-700 text-sm leading-sm font-medium flex items-center"
                >
                  {teams.find((type) => type.value === team)?.label}
                  <button
                    data-cy="remove-tag-2"
                    type="button"
                    aria-label="remove overdue filter"
                    className="text-gray-500"
                    onClick={() => {
                      onFilterChange('team', { value: teamId, label: teamName });
                      const newSearchParamsArray = filters.map((f) => ({ [f.name]: f.selectedValue.value }));
                      setSearchParams(Object.assign({}, ...newSearchParamsArray));
                      setTeam(teamId);
                    }}
                  >
                    <XMarkIcon className="w-4 h-4 ml-2" />
                  </button>
                </div>
              )}
              {period !== '0' && (
                <div
                  data-cy="tag-1"
                  className="bg-gray-100 rounded-2xl border border-gray-300 px-2.5 py-1 text-gray-700 text-sm leading-sm font-medium flex items-center"
                >
                  {periods.find((type) => type.value === period)?.label}
                </div>
              )}
            </div>
          </div>
          <div className="flex items-center justify-between lg:justify-normal gap-3 md:gap-9 w-full lg:w-fit mt-6 lg:mt-0">
            <CSVLink
              filename="rotaReport.csv"
              data={mappedShifts}
              className="text-gray-500 text-sm leading-sm font-semibold flex items-center hover:no-underline"
            >
              <ArrowUpTrayIcon className="w-5 h-5 mr-2" />
              Export CSV
            </CSVLink>
          </div>
        </div>
        <div className="mt-3 md:mt-12 mb-10 overflow-x-auto overflow-y-hidden">
          {mappedShifts && mappedShifts?.length > 0 && <Table<RotaReportType> data={mappedShifts} columns={columns1} />}
        </div>
      </div>
      {showFiltersModal && (
        <Filters
          filters={filters}
          onFilterChange={onFilterChange}
          onCloseModal={() => setShowFiltersModal(false)}
          onSaveFilters={onSaveFilters}
          showClearFilters={false}
        />
      )}
    </>
  );
}
