/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useChangeTeam,
  useGetCustomers,
  useActivateAccount,
  useTerminateAccount,
  useCreateBirdieAccount,
  useEditSupportedPerson,
} from 'api/hooks/useCustomers';
import { FormLabel, Input, Loading, LocationBar } from 'components';
import { ElderlyOutlined } from '@mui/icons-material';
import { Helmet } from 'react-helmet';
import { ArrowLeftIcon, ArrowPathIcon, PlusIcon } from '@heroicons/react/24/outline';
import { format } from 'date-fns';
import { FormProvider, useForm } from 'react-hook-form';
import TabSwitcher from 'components/TabSwitcher';
import { ImageField } from 'components/Fields/ImageField';
import { useReactToPrint } from 'react-to-print';
import WellbeingBoardNotes from 'pages/WellbeingBoard/WellbeingBoardCustomer/WellbeingBoardNotes';
import WellbeingBoardReviews from 'pages/WellbeingBoard/WellbeingBoardCustomer/WellbeingBoardReviews';
import { useCustomerAscotData } from 'pages/WellbeingBoard/WellbeingBoardCustomer/useCustomerAscotData';
import DatePicker from 'components/DatePicker';
import TextArea from 'components/TextArea';
import Message from '../../components/Message';
import { SupportedPeopleStateTransition } from './types';
import ManageSupportedPeopleDialog from './ManageSupportedPeopleDialog';
import ManageSupportedPeople from './ManageSupportedPeople';
import { Customer, CustomerEditInput, CustomerNote, CustomerReview, Maybe } from '../../__generated__/graphql';
import ManageSupportedPeopleAccounts from './ManageSupportedPeopleAccounts';
// import { ManageSupportedPeopleFamilyList } from './ManageSupportedPeopleFamilyList';
import FamilyMembers from './FamilyMembers';

const SupportedPeopleNoteList = ({ notes, notesRef }: { notes: Maybe<Maybe<CustomerNote>[]> | undefined; notesRef: (el: HTMLDivElement) => void }) => {
  return (
    <div ref={notesRef} className="invisible print:visible">
      <ul>
        <div className="text-black underline text-md sm:text-display-sm font-semibold leading-xl sm:leading-display-sm mb-1 invisible  print:visible">
          Notes
        </div>
        {notes?.map((n: Maybe<CustomerNote>) => {
          return (
            <li key={n?.id} className="  p-6 bg-white   rounded-lg shadow">
              <FormLabel label="Id">
                <div className="w-full lg:w-1/2">
                  <Input disabled={true} value={n?.id || ''} />
                </div>
              </FormLabel>
              <FormLabel label="Date">
                <div className="w-full lg:w-1/2">
                  <DatePicker disabled={true} value={n?.date ? new Date(n?.date) : undefined} />
                </div>
              </FormLabel>
              <FormLabel label="Note">
                <div className="w-full lg:w-1/2">
                  <div
                    className="bg-white px-5 py-2 disabled:bg-slate-50 disabled:text-slate-500 rounded-lg text-md leading-md text-gray-700
                  placeholder:text-gray-500 border border-gray-300 shadow-xs w-full "
                  >
                    {n?.note}
                  </div>
                </div>
              </FormLabel>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

const SupportedPeopleReviewList = ({
  reviews,
  reviewsRef,
}: {
  reviews: Maybe<Maybe<CustomerReview>[]> | undefined;
  reviewsRef: (el: HTMLDivElement) => void;
}) => {
  return (
    <div ref={reviewsRef} className="invisible print:visible  	">
      <ul>
        <div className="text-black underline text-md sm:text-display-sm font-semibold leading-xl sm:leading-display-sm mb-1 invisible  print:visible">
          Reviews
        </div>
        {reviews?.map((r: any) => {
          return (
            <li className="  p-6 bg-white   rounded-lg shadow">
              <FormLabel label="Id">
                <div className="w-full lg:w-1/2">
                  <Input disabled={true} value={r.id} />
                </div>
              </FormLabel>
              <FormLabel label="Requested By">
                <div className="w-full lg:w-1/2">
                  <Input disabled={true} value={r.requestedBy} />
                </div>
              </FormLabel>
              <FormLabel label="Date">
                <div className="w-full lg:w-1/2">
                  <DatePicker disabled={true} value={r?.date} />
                </div>
              </FormLabel>
              <FormLabel label="Note">
                <div className="w-full lg:w-1/2">
                  <TextArea disabled={true} value={r.note} />
                </div>
              </FormLabel>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

function MangageSupportedPeopleDetail() {
  const { supportedPersonId } = useParams();
  const { activateAccount, mutationActivateAccount } = useActivateAccount({ showAll: true });
  const { terminateAccount, mutationTerminateAccount } = useTerminateAccount({ showAll: true });
  const { createBirdieAccount, mutationCreateBirdieAccount } = useCreateBirdieAccount({ showAll: true });
  const { changeTeam, mutationChangeTeam } = useChangeTeam({ showAll: true });
  const { customers, loading } = useGetCustomers({ showAll: true });

  const customer = customers.filter((c: Customer) => c.id === supportedPersonId)[0];
  const { customerReviews } = useCustomerAscotData(customer);

  const { editCustomer, mutationEditCustomer } = useEditSupportedPerson({ id: customer?.id, teamId: customer?.teamId });
  const navigate = useNavigate();

  const [openSnack, setOpenSnack] = useState<boolean>(false);
  const [modalState, toggleModal] = useState<boolean>(false);
  const [manageType, setManageType] = useState<string>('');
  const [newTeamId, setNewTeam] = useState<string>('');
  const refs = useRef<HTMLDivElement[]>([]); // or an {}
  // Make it empty at every render cycle as we will get the full list of it at the end of the render cycle
  refs.current = []; // or an {}

  // since it is an array we need to method to add the refs
  const addToRefs = (el: HTMLDivElement) => {
    if (el && !refs.current.includes(el)) {
      refs.current.push(el);
    }
  };

  const methods = useForm<CustomerEditInput>({
    mode: 'onSubmit',
  });

  const { reset, handleSubmit } = methods;

  useEffect(() => {
    const placeOfBirth = { englishShortName: customer?.placeOfBirth?.englishShortName, alpha3Code: customer?.placeOfBirth?.alpha3Code };
    const ethnicity = { code: customer?.ethnicity?.code, description: customer?.ethnicity?.description };
    const sex = { code: customer?.sex?.code, description: customer?.sex?.description };
    const religion = { code: customer?.religion?.code, description: customer?.religion?.description };

    reset({
      ...customer,
      placeOfBirth,
      ethnicity,
      sex,
      religion,
    });
  }, [reset, customer]);

  const onSubmit = async (data: CustomerEditInput) => {
    await editCustomer({
      awaitRefetchQueries: true,
      variables: {
        input: {
          contactEmailAddress: data.contactEmailAddress,
          ethnicity: data.ethnicity,
          firstVisitDate: data.firstVisitDate,
          id: data.id,
          image: { id: data.image?.id, url: data.image?.url },
          nameSuffix: data.nameSuffix,
          nhsNumber: data.nhsNumber,
          placeOfBirth: data.placeOfBirth,
          preferredName: data.preferredName,
          religion: data.religion,
          sex: data.sex,
          teamId: data.teamId,
          title: data.title,
          wellbeingStatuses: data.wellbeingStatuses,
        },
      },
    });
    setOpenSnack(true);
    // navigate(`/supported-people/${customer?.id}`);
  };
  const onImageUpload = async (image: { url: string; id: string }) => {
    await editCustomer({
      variables: {
        input: {
          id: customer?.id,
          teamId: customer?.teamId,
          image: { url: image?.url, id: image?.id },
        },
      },
    });
  };

  const onChangeAccount = (type: string, teamId?: string) => {
    toggleModal(true);
    if (type) setManageType(type);
    if (teamId) setNewTeam(teamId);
  };

  const onConfirmChangeAccount = async () => {
    if (!customer) return null;

    const query = { variables: { input: { id: customer?.id, teamId: customer?.teamId } } };

    switch (manageType) {
      case SupportedPeopleStateTransition.terminated:
        activateAccount(query);
        break;
      case SupportedPeopleStateTransition.maps:
        activateAccount(query);
        break;
      case SupportedPeopleStateTransition.active:
        terminateAccount(query);
        break;
      case SupportedPeopleStateTransition.birdieDisabled:
        createBirdieAccount(query);
        break;
      case SupportedPeopleStateTransition.changeTeam:
        changeTeam({ variables: { input: { id: customer?.id, teamId: customer?.teamId, newTeamId } } });
        break;
      default:
        break;
    }

    setOpenSnack(true);
    toggleModal(false);
  };

  const handlePrint = useReactToPrint({
    content: () => {
      const PrintElem = document.createElement('div');
      PrintElem.setAttribute('style', 'padding:4em');
      refs?.current.forEach((ref) => {
        const clone = ref?.cloneNode(true);
        if (clone) PrintElem.appendChild(clone);
      });
      return PrintElem;
    },
  });

  const tabs = [
    {
      label: 'Details',
      component: (
        <ManageSupportedPeople detailRef={addToRefs} customer={customer} teamChangeLoading={mutationChangeTeam.loading} onChangeAccount={onChangeAccount} />
      ),
    },
    {
      label: 'Accounts',
      component: (
        <ManageSupportedPeopleAccounts
          onChangeAccount={onChangeAccount}
          accountRef={addToRefs}
          accountLoading={mutationTerminateAccount.loading || mutationActivateAccount.loading}
          birdieLoading={mutationCreateBirdieAccount.loading}
          customer={customer}
        />
      ),
    },
    {
      label: 'Family Members',
      component: <FamilyMembers customerId={customer?.id} teamId={customer?.teamId} familyMembersRef={addToRefs} />,
    },
    {
      label: 'Notes',
      component: <WellbeingBoardNotes notes={customer ? customer.notes : null} />,
      testId: 'wellbeing-board-notes-panel',
    },
    {
      label: 'Reviews',
      component: <WellbeingBoardReviews firstVisitDate={customer?.firstVisitDate ?? null} reviewsList={customerReviews || []} teamId={customer?.teamId} />,
      testId: 'wellbeing-board-reviews-panel',
    },
  ];

  if (loading) return <Loading />;

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Helmet>
            <title>Manage Supported People</title>
          </Helmet>
          <LocationBar section="Admin" page="Manage Support People" Icon={ElderlyOutlined} />
          <Message
            response={[mutationActivateAccount, mutationTerminateAccount, mutationCreateBirdieAccount, mutationEditCustomer]}
            openSnack={openSnack}
            setOpenSnack={setOpenSnack}
          />
          <ManageSupportedPeopleDialog title={manageType} onChange={onConfirmChangeAccount} modalState={modalState} toggleModal={toggleModal} />
          <div className="my-10 px-4 md:px-[5%]">
            <button
              type="button"
              className="text-gray-500 font-semibold text-md leading-md flex items-center"
              onClick={() => {
                navigate(-1);
              }}
            >
              <ArrowLeftIcon className="mr-2 w-5 h-5" /> Back
            </button>

            <div className="flex items-start justify-between mt-16">
              <div>
                <div className="flex items-center  py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                  <div className="ps-3">
                    <div ref={addToRefs}>
                      <ImageField onUpload={onImageUpload} image={customer?.image} />
                      <div className="text-black text-xl  sm:text-display-sm font-semibold leading-xl sm:leading-display-sm mb-1">{`${customer?.firstName} ${customer?.lastName}`}</div>
                    </div>
                    <button
                      data-cy="survey-detail-download"
                      type="button"
                      onClick={handlePrint}
                      className="text-white bg-primary-700 rounded-lg px-5 py-2.5 font-semibold text-md leading-md flex items-center gap-2 mb-5"
                    >
                      Download PDF
                    </button>
                  </div>
                </div>
              </div>
              <div>
                <div className="text-gray-400 text-xs font-medium leading-xs mb-2 flex items-center">
                  <PlusIcon className="w-3.5 h-3.5 mr-2" />
                  Created: {format(customer?.dateCreated ?? 0, 'dd/MM/yyyy')}
                </div>
                <div className="text-gray-400 text-xs font-medium leading-xs mb-2 flex items-center">
                  <ArrowPathIcon className="w-3.5 h-3.5 mr-2" />
                  Updated: {format(customer?.dateUpdated ?? 0, 'dd/MM/yyyy')}
                </div>
              </div>
            </div>
            <div className="mt-10 sm:mt-16">
              <TabSwitcher tabs={tabs} />
            </div>
          </div>
        </form>
      </FormProvider>
      <SupportedPeopleNoteList notes={customer.notes} notesRef={addToRefs} />
      <SupportedPeopleReviewList reviews={customer.reviews} reviewsRef={addToRefs} />
    </>
  );
}

export default MangageSupportedPeopleDetail;
