import TWModal from 'components/TWModal';
import { DateField } from 'components/Fields/DateField';
import { SelectField } from 'components/Fields/SelectField';
import { v4 as uuidv4 } from 'uuid';
import { FormProvider, useForm } from 'react-hook-form';
import { WellbeingStatus } from '../../../../__generated__/graphql';

interface AddWellbeingStatusProps {
  onClose: () => void;
  onAddStatus: (statuses: WellbeingStatus) => void;
}

const AddWellbeingStatus = ({ onClose, onAddStatus }: AddWellbeingStatusProps) => {
  const methods = useForm<{
    id: string;
    date: number;
    status: string;
  }>({
    mode: 'onSubmit',
    defaultValues: {
      id: uuidv4(),
      date: new Date().getTime(),
      status: 'On Track',
    },
  });

  const { handleSubmit } = methods;

  const onSubmit = (data: WellbeingStatus) => {
    onAddStatus(data);
  };

  return (
    <FormProvider {...methods}>
      <form>
        <TWModal title="Add wellbeingStatus" onClose={onClose} onSave={handleSubmit(onSubmit)} wFull testId="save-review-button">
          <DateField isRequired={true} label="Date" name="date" />
          <SelectField
            options={[
              { label: 'ON TRACK', value: 'On Track' },
              { label: 'OFF TRACK', value: 'Off Track' },
              { label: 'SLIGHTLY OFF TRACK', value: 'Slightly Off Track' },
              { label: 'PAUSED', value: 'Paused' },
            ]}
            label="Status"
            name="status"
            isRequired={true}
          />
        </TWModal>
      </form>
    </FormProvider>
  );
};

export default AddWellbeingStatus;
