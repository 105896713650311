import { useQuery, useMutation, gql, useLazyQuery } from '@apollo/client';
import {
  Answer,
  AnswerSearch,
  ConfirmationPracticeGroup,
  ConfirmationPracticeGroupInput,
  ConfirmationPracticeGroupSearch,
  ConfirmationPracticeQuestion,
  ConfirmationPracticeQuestionListSearch,
  GetAnswerSearch,
  TeamAnswer,
  TeamAnswerStatusSearch,
} from '../../__generated__/graphql';

export const GET_ANSWERS = gql`
  query GetAnswers($query: AnswerSearch) {
    GetAnswers(query: $query) {
      id
      score
      whyScore
      focus
      createdDateTime
      questionId
      groupId
    }
  }
`;

export const GET_ANSWER_STATUS = gql`
  query GetAnswerStatus($query: GetAnswerSearch) {
    GetAnswerStatus(query: $query)
  }
`;

export const GET_TEAM_ANSWER_STATUS = gql`
  query GetTeamAnswerStatus($query: TeamAnswerStatusSearch) {
    GetTeamAnswerStatus(query: $query) {
      id
      status
    }
  }
`;

export const SAVE_ANSWER = gql`
  mutation SaveAnswer($input: AnswerInput) {
    SaveAnswer(input: $input) {
      message
      id
    }
  }
`;

export const SAVE_CONFIRMATION_PRACTICE_QUESTION = gql`
  mutation SaveConfirmationPracticeQuestion($input: ConfirmationPracticeQuestionInput) {
    SaveConfirmationPracticeQuestion(input: $input) {
      message
      id
    }
  }
`;

export const SAVE_CONFIRMATION_PRACTICE_GROUP = gql`
  mutation SaveConfirmationPracticesGroup($input: ConfirmationPracticeGroupInput) {
    SaveConfirmationPracticesGroup(input: $input) {
      id
      message
      date
    }
  }
`;

export const GET_CONFIRMATION_PRACTICE_GROUPS = gql`
  query GetConfirmationPracticeGroups($query: ConfirmationPracticeGroupSearch) {
    GetConfirmationPracticeGroups(query: $query) {
      id
      supportWorkerId
      isSolo
      buddies {
        id
        fullName
        avatar
      }
      createdDateTime
      teamId
      answers {
        id
        supportWorkerId
        groupId
        questionId
        createdDateTime
        score
        whyScore
        focus
      }
      questions
    }
  }
`;

export const GET_CONFIRMATION_PRACTICES = gql`
  query GetConfirmationPracticesReport($query: ConfirmationPracticesReportSearch) {
    GetConfirmationPracticesReport(query: $query) {
      groupId
      createdDateTime
      fullName
      email
      teamName
      questionId
      score
      whyScore
      focus
      supportWorkerId
      numberOfShifts
    }
  }
`;

export const GET_CONFIRMATION_PRACTICE_QUESTIONS = gql`
  query GetConfirmationPracticeQuestions($query: ConfirmationPracticeQuestionListSearch) {
    GetConfirmationPracticeQuestions(query: $query) {
      createdAt
      id
      isActive
      question
      supportWorkerId
      title
    }
  }
`;

const refetch = (input: Record<string, unknown>) => ({
  refetchQueries: [
    {
      query: GET_CONFIRMATION_PRACTICE_GROUPS,
      variables: {
        query: { supportWorkerId: input.supportWorkerId },
      },
    },
  ],
});

const refetchQuestions = (query: ConfirmationPracticeQuestionListSearch) => ({
  refetchQueries: [
    {
      query: GET_CONFIRMATION_PRACTICE_QUESTIONS,
      variables: {
        query,
      },
    },
  ],
});

interface Response {
  GetConfirmationPracticeGroups: ConfirmationPracticeGroup[];
}

interface GetAnswersResponse {
  GetAnswers: Answer[];
}

type CPStatusesType = 'complete' | 'partComplete' | 'overdue' | 'error';

interface GetAnswerStatusResponse {
  GetAnswerStatus: CPStatusesType[];
}

interface GetTeamAnswerStatusResponse {
  GetTeamAnswerStatus: TeamAnswer[];
}

interface GetConfirmationPracticeQuestionsResponse {
  GetConfirmationPracticeQuestions: ConfirmationPracticeQuestion[];
}

export const useSaveConfirmationPracticesGroup = (input: ConfirmationPracticeGroupInput) => {
  const [saveConfirmationPracticesGroup, mutationSaveConfirmationPracticesGroup] = useMutation(SAVE_CONFIRMATION_PRACTICE_GROUP, refetch(input));
  return { saveConfirmationPracticesGroup, mutationSaveConfirmationPracticesGroup };
};

export const useGetConfirmationPracticeGroups = (query: ConfirmationPracticeGroupSearch) => {
  const { data, loading, error } = useQuery<Response>(GET_CONFIRMATION_PRACTICE_GROUPS, {
    variables: {
      query: { ...query },
    },
  });

  return { cpGroups: data?.GetConfirmationPracticeGroups || [], cpgLoading: loading, cpgError: error };
};

export const useSaveAnswer = () => {
  const [saveAnswer, { loading: mutationLoading, error: mutationError }] = useMutation(SAVE_ANSWER);

  return { saveAnswer, mutationLoading, mutationError };
};

export const useGetAnswers = (query: AnswerSearch) => {
  const {
    loading: answerLoading,
    error: answerError,
    data,
  } = useQuery<GetAnswersResponse>(GET_ANSWERS, {
    variables: {
      query: {
        ...query,
      },
    },
  });

  return { answers: data?.GetAnswers, answerError, answerLoading };
};

export const useGetAnswerStatus = (query: GetAnswerSearch) => {
  const { data, loading } = useQuery<GetAnswerStatusResponse>(GET_ANSWER_STATUS, {
    variables: {
      query: { ...query },
    },
  });

  return { answerStatus: data?.GetAnswerStatus || [], loading };
};

export const useGetTeamAnswerStatus = (query: TeamAnswerStatusSearch) => {
  const { data, loading } = useQuery<GetTeamAnswerStatusResponse>(GET_TEAM_ANSWER_STATUS, {
    variables: {
      query: { ...query },
    },
  });

  return { teamAnswerStatus: data?.GetTeamAnswerStatus || [], loading };
};

export const useGetConfirmationPracticesReport = () => {
  const [getConfirmationPracticesReport, { loading, error, data }] = useLazyQuery(GET_CONFIRMATION_PRACTICES);

  return { getConfirmationPracticesReport, loading, error, reports: data?.GetConfirmationPracticesReport || [] };
};

export const useSaveConfirmationPracticeQuestion = (query: ConfirmationPracticeQuestionListSearch) => {
  const [saveConfirmationPracticeQuestion, mutationSaveConfirmationPracticeQuestion] = useMutation(
    SAVE_CONFIRMATION_PRACTICE_QUESTION,
    refetchQuestions(query),
  );

  return { saveConfirmationPracticeQuestion, mutationSaveConfirmationPracticeQuestion };
};

export const useGetConfirmationPracticeQuestions = (query: ConfirmationPracticeQuestionListSearch) => {
  const { data, loading } = useQuery<GetConfirmationPracticeQuestionsResponse>(GET_CONFIRMATION_PRACTICE_QUESTIONS, {
    variables: {
      query: { ...query },
    },
  });

  return { confirmationPracticeQuestions: data?.GetConfirmationPracticeQuestions || [], loading };
};
