import { Input } from 'components';
import { Invoice } from '__generated__/graphql';
import { useEffect, useState } from 'react';
import { MapInvoice } from './Mapper';

interface InvoiceUpdateProps {
  invoice: Invoice | undefined;
  onSubmit: (invoice: Invoice) => void;
}

export default function InvoiceUpdate({ invoice, onSubmit }: InvoiceUpdateProps) {
  const [proRataRate, setProRataRate] = useState<string>('');
  const [proRataDescription, setProRataDescription] = useState<string>('');

  useEffect(() => {
    setProRataRate(invoice?.proRataRate || '');
    setProRataDescription(invoice?.proRataDescription || 'Additional charge for period - {startdate} to {enddate}');
  }, [invoice]);

  const onSaveInvoice = () => {
    onSubmit({
      ...MapInvoice(invoice),
      proRataRate,
      proRataDescription,
    });
  };

  return (
    <div className="mt-8 sm:mt-12">
      <div className="mb-5">
        <div className="text-sm font-medium text-gray-700 capitalize w-auto">Pro Rata Rate</div>
        <div className="mt-2">
          <Input value={proRataRate} onChange={setProRataRate} testId="invoice-proRataRate" />
        </div>
      </div>
      <div className="mb-5">
        <div className="text-sm font-medium text-gray-700 capitalize w-auto">Pro Rata Description</div>
        <div className="mt-2">
          <Input value={proRataDescription} onChange={setProRataDescription} testId="invoice-proRataDescription" />
        </div>
      </div>

      {invoice?.customerPackage && (
        <div className="flex justify-end">
          <button type="button" onClick={onSaveInvoice} className="text-white bg-primary-700 rounded-lg px-5 py-2.5 font-semibold text-md leading-md">
            Save
          </button>
        </div>
      )}
    </div>
  );
}
