import { AddOutlined } from '@mui/icons-material';
import { useMemo, useState } from 'react';
import { ConfirmationPracticeQuestion, ConfirmationPracticeQuestionInput } from '__generated__/graphql';
import { useUser } from 'context/userContext';
import { useGetConfirmationPracticeQuestions } from 'api/hooks/useConfirmationPractices';
import { CheckBadgeIcon, PencilIcon, XCircleIcon } from '@heroicons/react/24/outline';
import Alert from 'components/Alert';
import ConfirmationPracticeQuestionForm from './ConfirmationPracticeQuestionForm';

const ConfirmationPracticeQuestionList = ({
  onSaveConfirmationPracticeQuestion,
  saveLoading,
}: {
  onSaveConfirmationPracticeQuestion: (data: ConfirmationPracticeQuestionInput) => void;
  saveLoading: boolean;
}) => {
  const { user } = useUser();
  const { confirmationPracticeQuestions } = useGetConfirmationPracticeQuestions({ supportWorkerId: user?.profile });
  const [selectedQuestion, setSelectedQuestion] = useState<ConfirmationPracticeQuestion | null | undefined>();

  const [showEditWellbeingReview, setShowEditWellbeingReview] = useState(false);

  const onEditQuestion = (question: ConfirmationPracticeQuestion | null | undefined) => {
    setSelectedQuestion(question);
    setShowEditWellbeingReview(true);
  };
  const activeConfirmationQuestions = useMemo(() => confirmationPracticeQuestions.filter((q) => q.isActive), [confirmationPracticeQuestions]);
  const hasMaxQuestions = activeConfirmationQuestions.length === 5;
  return (
    <div>
      <div className="py-7">
        {hasMaxQuestions || saveLoading ? (
          <Alert title="You can only have 5 active addition confirmation questions" message="Please disable some to add more" />
        ) : (
          <button
            type="button"
            onClick={() => {
              setSelectedQuestion(undefined);
              setShowEditWellbeingReview(true);
            }}
            // eslint-disable-next-line max-len
            className="  border-primary-700 text-primary-700 rounded-lg px-5 py-2.5 font-semibold text-md leading-md flex items-center gap-2 w-fit mt-2  "
          >
            <AddOutlined className="w-5 h-5" />
            Add a question
          </button>
        )}
      </div>
      <div className="mt-3 md:mt-12 mb-10 scroll-m-3 overflow-x-auto overflow-y-hidden">
        <table className="table-auto min-w-full divide-y divide-gray-200">
          <thead>
            <tr>
              <td className="px-2 sm:px-4  md:px-6 py-3 text-left text-sm font-semibold text-gray-800"> Title</td>
              <td className="px-2 sm:px-4  md:px-6 py-3 text-left text-sm font-semibold text-gray-800">Question</td>
              <td className="px-2 sm:px-4  md:px-6 py-3 text-left text-sm font-semibold text-gray-800"> Is Active</td>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {confirmationPracticeQuestions.length > 0 &&
              confirmationPracticeQuestions.map((confirmationPracticeQuestion, index) => {
                return (
                  <tr key={index}>
                    <td className="px-2 sm:px-4 md:px-6 py-4 sm:whitespace-nowrap text-gray-700 text-md leading-md"> {confirmationPracticeQuestion.title}</td>
                    <td className="px-2 sm:px-4 md:px-6 py-4 sm:whitespace-nowrap text-gray-700 text-md leading-md">{confirmationPracticeQuestion.question}</td>
                    <td className="px-2 sm:px-4 md:px-6 py-4 sm:whitespace-nowrap text-gray-700 text-md leading-md">
                      {confirmationPracticeQuestion.isActive ? (
                        <CheckBadgeIcon className="w-5 h-5 text-success-500" />
                      ) : (
                        <XCircleIcon className="w-5 h-5 text-error-500" />
                      )}
                    </td>

                    <td className="px-2 sm:px-4 md:px-6 py-4 sm:whitespace-nowrap flex flex-row items-center gap-4">
                      <button type="button" aria-label="Edit button" onClick={() => onEditQuestion(confirmationPracticeQuestion)}>
                        <PencilIcon className="w-6 h-6 text-gray-500 hover:text-primary-600" />
                      </button>
                    </td>
                  </tr>
                );
              })}
            {confirmationPracticeQuestions.length === 0 && <div> No Questiions </div>}
          </tbody>
        </table>
      </div>
      {showEditWellbeingReview && (
        <ConfirmationPracticeQuestionForm
          activeConfirmationQuestions={activeConfirmationQuestions}
          question={selectedQuestion}
          onClose={() => setShowEditWellbeingReview(false)}
          onSaveConfirmationPracticeQuestion={onSaveConfirmationPracticeQuestion}
        />
      )}
    </div>
  );
};

export default ConfirmationPracticeQuestionList;
